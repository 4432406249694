<template>
  <CreateLoggerModelStyled>
    <header>Create Logger Model</header>
    <InputStyled :required="!$v.name.required">
      <input v-model.trim="$v.name.$model" :placeholder="$tc('loggerModel')" />
    </InputStyled>
    <p class="errors" v-if="!$v.name.isUnique">{{ $tc('messages.notUnique') }}</p>
    <p class="errors" v-if="$v.name.$dirty && !$v.name.required">{{ $tc('messages.required') }}</p>
    <p class="error" v-if="!$v.name.minLength">Field must have at least {{ $v.name.$params.minLength.min }} letters.</p>
    <div class="type">
      <div class="type-label">Manufacturer *</div>
      <Multiselect
        :allow-empty="false"
        :options="organizations"
        :value="selectedManufacturer"
        :loading="$apollo.queries.organizations.loading"
        trackBy="id"
        label="name"
        @select="setOrganizationsModel"
      />
    </div>
    <ToggleStyled>
      <span>has GPS *</span>
      <OnOffToggle class="on-off-toggle" :value="hasGps" @toggle="hasGps = !hasGps" />
    </ToggleStyled>
    <ButtonsGroup>
      <ButtonStyled
        :disabled="!$v.name.required || !$v.name.minLength || !$v.name.isUnique || !selectedManufacturer"
        @click="saveLoggerModel"
        >{{ $tc('actions.create') }}</ButtonStyled
      >
      <ButtonStyled color="grey" @click="$root.$emit('closeOverlay')">{{ $tc('actions.cancel') }}</ButtonStyled>
    </ButtonsGroup>
  </CreateLoggerModelStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import Multiselect from 'vue-multiselect'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { buttonReset, flexCenter } from '@styles/mixins'
import { OnOffToggle } from '@common/components'
import LOGGER_MODELS_QUERY from '#/graphql/loggerModels/models.gql'
import CREATE_LOGGER_MODEL_MUTATION from '#/graphql/loggerModels/create.gql'
import ORGANIZATIONS_QUERY from '#/graphql/organizations/organizations.gql'

const InputStyled = styled('div')`
  width: 100%;
  padding: 1rem 0;
  input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    color: ${props => props.theme.colors.white};
    background: ${props => chroma(props.theme.colors.white).alpha(0.1).css()};
    border-bottom: 1px solid
      ${props => (props.required ? chroma(props.theme.colors.red).alpha(0.8).css() : chroma(props.theme.colors.green).alpha(0.8).css())};
    transition: border-color 0.5s ease;

    &:focus {
      border-color: ${props => props.theme.colors.primary};
    }

    &::placeholder {
      color: ${props => chroma(props.theme.colors.white).alpha(0.5).css()};
    }
  }
`
const ToggleStyled = styled('div')`
  display: flex;
  align-items: center;
  padding: 1rem 0;

  > span {
    margin-right: 1rem;
  }
`

const ButtonStyled = styled('button')`
  ${props => buttonReset(props)}
  ${flexCenter}
  margin: .5rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background: ${props => props.theme.colors.orange};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryLighter};
  }
  &:disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
  }
`

const ButtonsGroup = styled('div')`
  display: flex;
  flex-direction: row;
  float: right;
`

const CreateLoggerModelStyled = styled('div')`
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  padding-top: 50px;

  .errors {
    color: red;
  }
`
export default {
  components: {
    CreateLoggerModelStyled,
    InputStyled,
    ButtonsGroup,
    ButtonStyled,
    Multiselect,
    ToggleStyled,
    OnOffToggle,
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      minLength: minLength(1),
      isUnique() {
        const found = this.loggerModels.find(item => item.name.toLowerCase() === this.name.toLowerCase())
        if (found) {
          return false
        }
        return true
      },
    },
  },
  data() {
    return {
      name: '',
      loggerModels: [],
      selectedManufacturer: '',
      hasGps: true,
    }
  },
  methods: {
    async saveLoggerModel() {
      const data = {
        name: this.name,
        manufacturerCuid: this.selectedManufacturer.id,
        hasGps: this.hasGps,
      }
      await this.$apollo.mutate({
        mutation: CREATE_LOGGER_MODEL_MUTATION,
        variables: {
          data,
        },
        update: (store, { data: { createLoggerModel } }) => {
          let data = store.readQuery({
            query: LOGGER_MODELS_QUERY,
          })
          data.loggerModels.push(createLoggerModel)
          store.writeQuery({
            query: LOGGER_MODELS_QUERY,
            data,
          })
          this.$root.$emit('closeOverlay')
        },
      })
    },
    setOrganizationsModel(model) {
      this.selectedManufacturer = model
    },
  },
  apollo: {
    loggerModels: {
      query: LOGGER_MODELS_QUERY,
    },
    organizations: {
      query: ORGANIZATIONS_QUERY,
    },
  },
}
</script>
