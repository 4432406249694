var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CreateLoggerModelStyled', [_c('header', [_vm._v("Create Logger Model")]), _c('InputStyled', {
    attrs: {
      "required": !_vm.$v.name.required
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.name.$model,
      expression: "$v.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    attrs: {
      "placeholder": _vm.$tc('loggerModel')
    },
    domProps: {
      "value": _vm.$v.name.$model
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.name, "$model", $event.target.value.trim());
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), !_vm.$v.name.isUnique ? _c('p', {
    staticClass: "errors"
  }, [_vm._v(_vm._s(_vm.$tc('messages.notUnique')))]) : _vm._e(), _vm.$v.name.$dirty && !_vm.$v.name.required ? _c('p', {
    staticClass: "errors"
  }, [_vm._v(_vm._s(_vm.$tc('messages.required')))]) : _vm._e(), !_vm.$v.name.minLength ? _c('p', {
    staticClass: "error"
  }, [_vm._v("Field must have at least " + _vm._s(_vm.$v.name.$params.minLength.min) + " letters.")]) : _vm._e(), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v("Manufacturer *")]), _c('Multiselect', {
    attrs: {
      "allow-empty": false,
      "options": _vm.organizations,
      "value": _vm.selectedManufacturer,
      "loading": _vm.$apollo.queries.organizations.loading,
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.setOrganizationsModel
    }
  })], 1), _c('ToggleStyled', [_c('span', [_vm._v("has GPS *")]), _c('OnOffToggle', {
    staticClass: "on-off-toggle",
    attrs: {
      "value": _vm.hasGps
    },
    on: {
      "toggle": function toggle($event) {
        _vm.hasGps = !_vm.hasGps;
      }
    }
  })], 1), _c('ButtonsGroup', [_c('ButtonStyled', {
    attrs: {
      "disabled": !_vm.$v.name.required || !_vm.$v.name.minLength || !_vm.$v.name.isUnique || !_vm.selectedManufacturer
    },
    on: {
      "click": _vm.saveLoggerModel
    }
  }, [_vm._v(_vm._s(_vm.$tc('actions.create')))]), _c('ButtonStyled', {
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('closeOverlay');
      }
    }
  }, [_vm._v(_vm._s(_vm.$tc('actions.cancel')))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }